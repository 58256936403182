import "./App.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import NaturePage from "./pages/NaturePage";
import AnimalsPage from "./pages/AnimalsPage";
import UrbanPage from "./pages/UrbanPage";
import NotFound from "./pages/NotFound";
import ReactGA from "react-ga";
import Login from "./pages/Login.jsx";
import IsAdmin from "./components/IsAdmin";
import Dashboard from "./pages/Dashboard.jsx";
import GocAlbum from "./pages/GocAlbum";
import ForestAlbum from "./pages/ForestAlbum";
import AboutPage from "./pages/AboutPage";
import TagManager from "react-gtm-module";

const TRACKING_ID = "G-JB1NNWPQ9P";
ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
  gtmId: "GTM-KJJ29PR6",
};

TagManager.initialize(tagManagerArgs);

function App() {
  window.dataLayer.push({
    event: "pageview",
  });

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/nature" element={<NaturePage />} />
        <Route path="/nature-mountains" element={<GocAlbum />} />
        <Route path="/nature-forest" element={<ForestAlbum />} />
        <Route path="/animals" element={<AnimalsPage />} />
        <Route path="/urban" element={<UrbanPage />} />
        <Route path="/about-me" element={<AboutPage />} />
        <Route
          path="/dashboard"
          element={
            <IsAdmin>
              <Dashboard />
            </IsAdmin>
          }
        />
        <Route path="/markoadmin" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
