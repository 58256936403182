import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import LongMenu from "../components/LongMenu";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../components/loadingSpinner/LoadingSpinner";
import ReactGA from "react-ga";
import ModalImg from "../components/ModalImg";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-KJJ29PR6",
};

TagManager.initialize(tagManagerArgs);

function NaturePage() {
  window.dataLayer.push({
    event: "pageview",
  });

  const [image, setImage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentNameIndex, setCurrentNameIndex] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [imageDescription, setImageDescription] = useState(null);
  const [showImageDetails, setShowImageDetails] = useState(null);
  const [showAlbumModal, setShowAlbumModal] = useState(false);

  const { isLoggedIn } = useContext(AuthContext);

  const navigate = useNavigate();

  //commit comment

  const handleClick = (picture, index) => {
    setCurrentIndex(index);
    setClickedImg(picture.link);
    setImageName(picture.name);
    setImageDescription(picture.description);
  };

  const handelRotationLeft = () => {
    setCurrentIndex((prev) => prev - 1);
    setClickedImg(image[currentIndex - 1].link);
    setImageName(image[currentIndex - 1].name);
    setImageDescription(image[currentIndex - 1].description);
  };

  const handelRotationRight = () => {
    setCurrentIndex((prev) => prev + 1);
    setClickedImg(image[currentIndex + 1].link);
    setImageName(image[currentIndex + 1].name);
    setImageDescription(image[currentIndex + 1].description);
  };

  const getNatureImages = () => {
    setIsLoading(true);
    axios
      .get("https://mi-photography-backend.vercel.app/images/nature")
      .then((response) => {
        setImage(response.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getNatureImages();
    ReactGA.pageview(window.location.pathname);
  }, []);

  // clickedImg ? document.body.style.overflow = "hidden" : document.body.style.overflow = "hidden";

  return (
    <>
      <div>
        <Helmet>
          <title>Marko Ivezic Photography | Nature</title>
          <meta
            name="description"
            content="Immerse yourself in breathtaking nature photography that showcases the wonders of the natural world. From serene landscapes to vibrant flora, explore a collection of images that capture the beauty and tranquility of nature."
          />
        </Helmet>
      </div>
      {isLoading && <LoadingSpinner />}
      {!isLoading && <NavBar />}
      {!isLoading && <LongMenu />}
      {!isLoading && (
        <div className="natureImageCover">
          <h3>Nature Photos</h3>
          <hr style={{ width: "50px", color: "white" }} />
          <p style={{ width: "300px" }}>
            Do you have a passion for nature? Here, you can learn about the
            wonders of our planet and explore its wild places.
          </p>
          <button
            className="albumPrintButton"
            onClick={() =>
              (window.location.href =
                "https://pixels.com/profiles/marko-ivezic/collections/nature")
            }
          >
            Nature Prints
          </button>
        </div>
      )}
      {!isLoading && (
        <div className="mountainsAlbum">
          <div style={{ width: "auto", height: "auto" }}></div>
          <div
            onClick={() => navigate("/nature-mountains")}
            className="albumModal"
          >
            <h3>Mountains</h3>
            <hr style={{ width: "5%" }} />
            <p>
              Embark on a breathtaking visual journey through majestic mountains
              captured in my stunning photo album
            </p>
          </div>
        </div>
      )}
      {!isLoading && (
        <div className="forestAlbum">
          <div style={{ width: "auto", height: "auto" }}></div>
          <div
            onClick={() => navigate("/nature-forest")}
            className="albumModal"
          >
            <h3>Forest</h3>
            <hr style={{ width: "5%" }} />
            <p>
              The forest photo album is a captivating collection of images
              capturing the serene beauty and enchanting atmosphere of various
              forests around the world.
            </p>
          </div>
        </div>
      )}
      {!isLoading && (
        <div style={{ padding: "50px" }}>
          <h3>All landscape and nature photos</h3>
          <hr style={{ width: "10%" }} />
        </div>
      )}
      <div className="gallery-image">
        {image.map((picture, index) => (
          <div
            key={index}
            className="img-box"
            onClick={() => handleClick(picture, index)}
          >
            <img className="actual-img" src={picture.link} alt="" loading="lazy" />
            <div className="transparent-box">
              <div className="caption">
                <p>{picture.name}</p>
                <p className="opacity-low">{picture.description}</p>
              </div>
            </div>
          </div>
        ))}
        {clickedImg && (
          <ModalImg
            image={image}
            handelRotationLeft={handelRotationLeft}
            handelRotationRight={handelRotationRight}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            clickedImg={clickedImg}
            setClickedImg={setClickedImg}
            imageName={imageName}
            imageDescription={imageDescription}
            showImageDetails={showImageDetails}
            setShowImageDetails={setShowImageDetails}
          />
        )}
      </div>
      {!isLoading && <Footer />}
    </>
  );
}

export default NaturePage;
