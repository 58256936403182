import React from "react";
import { useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";
import image from "../images/1712250135535.png";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import LongMenu from "../components/LongMenu"

const tagManagerArgs = {
  gtmId: "GTM-KJJ29PR6",
};

TagManager.initialize(tagManagerArgs);

function AboutPage(props) {
  window.dataLayer.push({
    event: "pageview",
  });

  const Image = image;

  function getWindowSize() {
    const { innerWidth } = window;
    return { innerWidth };
  }

  const [isMobile, setIsMobile] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  console.log(windowSize);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);
    if (windowSize.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div>
      <div>
        <Helmet>
          <title>Marko Ivezic Photography | About-me</title>
        </Helmet>
      </div>
      <NavBar />
      <LongMenu />
      <div>
        <img className="aboutCoverImage" src={Image} alt="about cover" />
      </div>
      <div className="aboutMeHead">
        <h2>About me</h2>
        <hr className="hrDevider" />
        <p className="aboutMeDescription">
          Born in Serbia in a small place surrounded with beautiful nature and
          mountains where i spent a lot of time hiking, exploring and taking
          photos in order to remember the moment. Very fast I knew that
          photography was my passion and I spent more and more time learning new
          techniques mixed with stunning nature which helped me improve my
          photography skills. With my first DSLR I moved my photography skills
          to a higher level, learning more advanced ways of photography and
          adapting to different kind of environment. Capturing the beauty of the
          world around me has become more than a hobby. There's something about
          freezing that perfect moment when the sun kisses the horizon or when
          storm clouds dance across the sky. Each photo is a story, a visual
          poem that speaks volumes without uttering a single word. The world is
          a canvas, and I see myself as a humble artist, armed with a camera
          instead of a paintbrush. Whether it's the serene stillness of a
          mountain lake, the golden hues of a sunset, the raw power of a
          cascading waterfall, or the captivating gaze of a wild animal, I find
          myself spellbound by the wonders of nature. Wildlife photography adds
          another layer to this enchantment, allowing me to glimpse into the
          lives of creatures big and small, each with its own story to tell
          amidst the grand tapestry of our planet's beauty.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default AboutPage;
