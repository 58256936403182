import axios from "axios";
import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import LongMenu from "../components/LongMenu";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../components/loadingSpinner/LoadingSpinner";
import ReactGA from "react-ga";
import ModalImg from "../components/ModalImg";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-KJJ29PR6",
};

TagManager.initialize(tagManagerArgs);

function AnimalsPage() {
  window.dataLayer.push({
    event: "pageview",
  });

  const [image, setImage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentNameIndex, setCurrentNameIndex] = useState(null);
  const [imageName, setImageName] = useState([]);
  const [imageDescription, setImageDescription] = useState([]);
  const [showImageDetails, setShowImageDetails] = useState(null);

  const handleClick = (picture, index) => {
    setCurrentIndex(index);
    setCurrentNameIndex(index);
    setClickedImg(picture.link);
    setImageName(picture.name);
    setImageDescription(picture.description);
  };

  const handelRotationLeft = () => {
    setCurrentIndex((prev) => prev - 1);
    setClickedImg(image[currentIndex - 1].link);
    setImageName(image[currentIndex - 1].name);
    setImageDescription(image[currentIndex - 1].description);
  };

  const handelRotationRight = () => {
    setCurrentIndex((prev) => prev + 1);
    setClickedImg(image[currentIndex + 1].link);
    setImageName(image[currentIndex + 1].name);
    setImageDescription(image[currentIndex + 1].description);
  };

  const getAnimalImages = () => {
    setIsLoading(true);
    axios
      .get("https://mi-photography-backend.vercel.app/images/animals")
      .then((response) => {
        setImage(response.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAnimalImages();
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <div>
        <Helmet>
          <title>Marko Ivezic Photography | Animals</title>
          <meta
            name="description"
            content="Explore stunning wildlife photography featuring majestic animals in their natural habitats. Discover captivating images of birds, mammals, reptiles, and more, capturing the beauty and diversity of the animal kingdom."
          />
        </Helmet>
      </div>
      {isLoading && <LoadingSpinner />}
      {!isLoading && <NavBar />}
      {!isLoading && <LongMenu />}
      {/* {!isLoading && <AnimalsCoverPhoto />} */}
      {!isLoading && (
        <div className="animalsImageCover">
          {/* <h3>Animals Photos</h3>
          <hr style={{ width: "50px", color: "white" }} />
          <p style={{ width: "300px" }}>
            A tribute to wildlife and our beloved pets.
          </p> */}
          {/* <button
            className="albumPrintButton"
            onClick={() =>
              (window.location.href =
                "https://pixels.com/profiles/marko-ivezic/collections/nature")
            }
          >
            Nature Prints
          </button> */}
        </div>
      )}
      {!isLoading && <h3 className="animalsPageTitle">Animals Photos</h3>}
      <div className="gallery-image">
        {image.map((picture, index) => (
          <div
            key={index}
            className="img-box"
            onClick={() => handleClick(picture, index)}
          >
            <img
              className="actual-img"
              src={picture.link}
              alt=""
              loading="lazy"
            />
            <div className="transparent-box">
              <div className="caption">
                <p>{picture.name}</p>
                <p className="opacity-low">{picture.description}</p>
              </div>
            </div>
          </div>
        ))}
        {clickedImg && (
          <ModalImg
            image={image}
            handelRotationLeft={handelRotationLeft}
            handelRotationRight={handelRotationRight}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            clickedImg={clickedImg}
            setClickedImg={setClickedImg}
            imageName={imageName}
            imageDescription={imageDescription}
            showImageDetails={showImageDetails}
            setShowImageDetails={setShowImageDetails}
          />
        )}
      </div>
      {!isLoading && <Footer />}
    </>
  );
}

export default AnimalsPage;
